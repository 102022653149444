import React from 'react'
import style from './Error.module.css'
import logo from '../../../assets/images/svg/moons.svg'

const logoComponent = (
  <div className={style.Logo}>
      <img
        alt="logo"
        src={logo}
      />
    </div>
)

/**
 * Error Component
 * @param {Function} handleErrorButton - function to handle the button on error component
 * @param {Object} responseError - json to the response of the error
 */
export default class Error extends React.Component {
  /**
   *
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    const { handleErrorButton, responseError } = this.props
    this.handleErrorButton = handleErrorButton
    this.responseError = responseError
  }

  /**
   * @param {Event} event - onClick event
   * @return {void} .
   */
  handleClick = event => {
    event.preventDefault()
    const error = false
    this.handleErrorButton(error)
  }

  /**
   * @returns {void}
   */
  render() {
    const position = 0
    const label = 'Intentar nuevamente'
    const title = '¡Oh, no!'
    const subtitle = 'Algo salió mal con tu pago.'
    const { message } = this.responseError.message
  
    window.scrollTo(position, position)
    return (
      <div className={style.ErrorContainer}>
         {logoComponent}
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
        {message}
        <div className={style.buttonContainer}>
          <button
            type="button"
            className={style.TryAgain}
            onClick={this.handleClick}
          >
            {label}
          </button>
        </div>
      </div>
    )
  }
}
