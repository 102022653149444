import React from 'react'
import Checkout from '../Checkout/Checkout'
import ErrorQuery from './ErrorQuery'
import Services from '../../Apis/Back4AppServices'
import { withRouter } from 'react-router-dom'

class CheckoutFromPaymentId extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      success: null,
      loading: true
    }
    
    const products = {
      'home-kit':'moons-kit-hotsale-checkout',
      'moons':'anticipo-moons-hotsale-chekout',
      'test-devs':'test-devs',
      'simple':'moons-simple-promo',
      'liquidacion':'liquidacion',
      'custom':'custom',
      'moderado':'moons-moderado-promo',
      'complejo':'moons-complejo-promo'
    }

    const match = this.props.match;
    const params = match.params ? match.params : null;
    this.product  = params.product ? params.product : null;
    this.paymentId = params.paymentId ? params.paymentId : null;
    this.customerData = {};
    this.customerId = null;
    this.productKeyName = products[this.product] ? products[this.product] : false;
  }

  async componentDidMount() {
    const patientData = await Services.getPatientByPaymentId(this.paymentId)
    console.log(patientData)
    if(patientData) {
      this.customerData.name = patientData.Patient_Name
      this.customerData.email = patientData.Email
      this.customerData.phone = patientData.Phone
      this.customerId = patientData.CustomerId
      this.setState({success: true, loading: false})

    } else {
      this.setState({success : false, loading: false})
    }
  }

  render() {
    const {
      country,
      paymentKeyName,
      isAdvance,
      showPersonalData,
      showAddress,
      showCoupon,
      showProductInformation,
      cardPaymentMethod,
      cashPaymentMethod,
    } = this.props
    const { success } = this.state;

    if(!this.state.loading){
      if(!success) {
        return <ErrorQuery/>
      }
      else {
        return (
          <Checkout
            country={country}
            productKeyName={this.productKeyName}
            paymentKeyName={paymentKeyName}//"kutfgyhuj"
            customerId={this.customerId}
            isAdvance={isAdvance}
            showPersonalData={showPersonalData}
            showAddress={showAddress}
            showCoupon={showCoupon}
            showProductInformation={showProductInformation}
            cardPaymentMethod={cardPaymentMethod}
            cashPaymentMethod={cashPaymentMethod}
            customerData={this.customerData}
          />
        )
      }
    }else{
      return <></>
    }
  }
}

export default withRouter( CheckoutFromPaymentId )
