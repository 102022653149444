import React from 'react'
import styles from '../CheckoutForm.module.css'

/**
 * Form Group component
 * @param {string} idInput - id of the input
 * @param {boolean} error - true if there was an error
 * @param {string} errorText - text to show when error occured
 * @param {string} typeInput - type of input (text,number,email)
 * @param {string} textLabel - text to present in the label
 * @return {void}
 */
const FormGroup = ({
  idInput,
  error,
  errorText,
  typeInput,
  textLabel,
  refInput,
  onChange
}) => {
  let errorSpan = null
  if (error) {
    errorSpan = <span className={styles.ErrorMessage}>{errorText}</span>
  }
  const labelId = `label-${idInput}`
  return (
    <div className={styles.Form}>
      <label
        id={labelId}
        htmlFor={idInput}
        className={styles.Input}
      >
        <input
          type={typeInput}
          id={idInput}
          ref={refInput}
          placeholder="&nbsp;"
          onChange={onChange}
        />
        <span className={styles.Label}>{textLabel}</span>
        <span className={styles.Border} />
      </label>
      {errorSpan}
    </div>
  )
}

export default FormGroup
