
const messages = {
  installmentsType: [
    {
      id: 0,
      text: 'Un solo pago de {priceTotal} MXN',
      value: 0,
    },
    {
      id: 1,
      text: '{price} a 3 meses sin intereses',
      value: 3,
    },
    {
      id: 2,
      text: '{price} a 6 meses sin intereses',
      value: 6,
    },
    {
      id: 3,
      text: '{price} a 9 meses sin intereses',
      value: 9,
    },
    {
      id: 4,
      text: '{price} a 12 meses sin intereses',
      value: 12,
    }
  ],
}

export default messages
