import React from 'react'
import styles from './Address.module.css'
import FormGroup from '../FormGroup/FormGroup'

/**
 * Address Componenet
 * @param {*} props - .
 * @param {boolean} errorStreet - true if error on street input
 * @param {boolean} errorColony - true if error on colony input
 * @param {boolean} errorState - true if error on state input
 * @param {string}  streetTextLabel - text for street label input
 * @param {string}  colonyTextlabel - text for colony label input
 * @param {string}  stateTextLabel -  text for state label input
 * @returns {void} .
 */
const Address = (props) => {
  const {
    errorStreet,
    errorColony,
    errorState,
    streetTextLabel,
    colonyTextLabel,
    stateTextLabel,
    refAddress
  } = props
  const errorTextStreet = 'Ingresa una calle'
  const errorTextColony = 'Ingresa una colonia'
  const errorTextState = 'Ingresa un estado'
  return (
    <div className={styles.AddressContainer}>
      <FormGroup
        idInput="street"
        error={errorStreet}
        errorText={errorTextStreet}
        typeInput="text"
        textLabel={streetTextLabel}
        refInput={refAddress[0]}
      />
      <FormGroup
        idInput="colony"
        error={errorColony}
        errorText={errorTextColony}
        typeInput="text"
        textLabel={colonyTextLabel}
        refInput={refAddress[1]}
      />
      <FormGroup
        idInput="state"
        error={errorState}
        errorText={errorTextState}
        typeInput="text"
        textLabel={stateTextLabel}
        refInput={refAddress[2]}
      />
    </div>
  )
}

export default Address
