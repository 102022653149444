import Parse from 'parse'

Parse.serverURL = process.env.REACT_APP_SERVER_URL
Parse.initialize(process.env.REACT_APP_APPLICATION_ID,process.env.REACT_APP_JAVASCRIPT_KEY,process.env.REACT_APP_MASTER_KEY)


const Services = {

  /**
   * Get transaction log by id.
   * @param {string} id .
   * @returns {void} .
   */
  getConektaOxxoById: async id => {
    const ConektaOxxo = Parse.Object.extend('ConektaOxxo');
    const query = new Parse.Query(ConektaOxxo);

    query.equalTo('Payment_Id', id)
    const response = await query.first().then(
      results => {
        let data = null
        if (results) {
          data = JSON.stringify(results)
        }
        return data
      }
    )
    return JSON.parse(response)
  },

  /**
   * Get transaction log by id.
   * @param {string} name .
   * @returns {void} .
   */
  hasInstallmentsByName: async name => {
    const Product = Parse.Object.extend('Product')
    const query = new Parse.Query(Product)

    query.equalTo('keyName', name)
    const response = await query.first().then(
      results => {
        let data = null
        let installments = false
        let installmentsPrice = null
        if (results) {
          data = JSON.stringify(results)
          installments = JSON.parse(data).installments
          installmentsPrice = JSON.parse(data).installmentsPrice
        }
        return { installments, installmentsPrice }
      }
    )
    return response
  },

  /**
   * Get the product by its keyname
   * @param {String} keyName - keyname of the product
   * @return {void} .
   */
  getProductByKeyName: async keyName => {
    try {
      const Product = Parse.Object.extend('Product')
      const query = new Parse.Query(Product)
      query.equalTo('keyName', keyName)
      const product = await query.first()
      let dataProduct = null
      if (product) {
        const data = JSON.stringify(product)
        dataProduct = JSON.parse(data)
        return dataProduct
      }
      return product
    } catch (errorParse) {
      return { error: true, errorParse }
    }
  },

  /**
   * Get the paymentLink by its keyname
   * @param {String} keyName - keyname of the paymentLink
   * @return {void} .
   */
  getPaymentLinkByKeyName: async keyName => {
    try {
      const PaymentLink = Parse.Object.extend('PaymentLink')
      const query = new Parse.Query(PaymentLink)
      query.equalTo('paymentKey', keyName)
      const paymentLink = await query.first()
      let dataProduct = null
      if (paymentLink) {
        const data = JSON.stringify(paymentLink)
        dataProduct = JSON.parse(data)
        return dataProduct
      }
      return paymentLink
    } catch (errorParse) {
      return { error: true, errorParse }
    }
  },

  /**
   * Get Patient By CustomerId
   * @param {String} customerId - customer id
   * @return {void} .
   */
  getPatientByCustomerId: async customerId => {
    try {
      const Patient = Parse.Object.extend('Patient')
      const query = new Parse.Query(Patient)
      query.equalTo('CustomerId', customerId)
      let patient = await query.first()
      let dataPatient = null
      if (patient) {
        const data = JSON.stringify(patient)
        dataPatient = JSON.parse(data)
        return dataPatient
      }
      return patient
    } catch (errorParse) {
      return { error: true, errorParse }
    }
  },

  getPatientByPaymentId: async paymentId => {
    try{
      const Patient = Parse.Object.extend('Patient');
      const query = new Parse.Query(Patient);
      query.equalTo('PaymentId',paymentId)
      let patient = await query.first()
      let dataPatient = null;
      if(patient) {
        const data = JSON.stringify(patient)
        dataPatient = JSON.parse(data)
        console.log(dataPatient)
        return dataPatient
      }
      return dataPatient
    }catch(errorParse) {
      return {error : true, errorParse}
    }
  },
  getCoupon: async (code,productKeyName) => {
    try{
      const Coupon = Parse.Object.extend('Coupon');
      const query = new Parse.Query(Coupon);
      query.equalTo('Code',code)
      let coupon = await query.first();
      let dataCoupon = null;
      if (coupon) {
        const data = JSON.stringify(coupon);
        dataCoupon = JSON.parse(data);
        if(!dataCoupon.Product_Restriction.includes(productKeyName)){
          return null;
        }
      }
      return dataCoupon;
    }catch(err) {
      throw err;
    }
  }
}

export default Services
