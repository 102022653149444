
const messages = {
  installmentsType: [
    {
      id: 0,
      text: 'Un solo pago de {priceTotal} MXN',
      value: 0,
      percent: 0
    },
    {
      id: 1,
      text: '3 pagos de {price}',
      value: 3,
      percent: 5
    },
    {
      id: 2,
      text: '6 pagos de {price}',
      value: 6,
      percent: 7.5
    },
    {
      id: 3,
      text: '9 pagos de {price}',
      value: 9,
      percent: 10
    },
    {
      id: 4,
      text: '12 pagos de {price}',
      value: 12,
      percent: 12.5
    }
  ],
}

export default messages
