/* eslint-disable react/jsx-max-depth */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component, createRef } from 'react'
import styles from './FormCard.module.css'

/**
 * Form component.
 * @returns {void} .
 */
export default class FormCard extends Component {
  /**
   * Constructor.
   * @param {object} props .
   * @returns {void} .
   */
  constructor() {
    super()
    this.state = {
      cardValue: '',
      cvcValue: '',
      yearValue: '',
      monthValue: '',
      dateBool: true
    }
    this.refCard = createRef()
    this.refCode = createRef()
  }

  handleCardNumber = ( event ) => {
    const re = /^[0-9\b]+$/;
    if ( event.target.value === '' || re.test( event.target.value ) ) {
      if ( event.target.value.toString().length <= 16 ) {
        this.setState( { cardValue: event.target.value } )
      }
    }
  }

  onKeyDate = ( event ) => {
    const re = /^[0-9/\b]+$/
    if ( event.target.value === '' || re.test( event.target.value ) ) {
      if ( event.target.value.toString().length <= 5 ) {
        if ( event.target.value.toString().length === 2 ) {
          const slash = '/'
          this.setState( { dateValue: event.target.value + slash } )
        } else {
          this.setState( { dateValue: event.target.value } )
        }
      }
    }
  }

  handleYear = ( event ) => {
    const re = /^[0-9\b]+$/
    if ( event.target.value === '' || re.test( event.target.value ) ) {
      if ( event.target.value.toString().length <= 4 ) {
        this.setState( { yearValue: event.target.value } )
      }
    }
  }

  handleMonth = ( event ) => {
    const re = /^[0-9\b]+$/
    if ( event.target.value === '' || re.test( event.target.value ) ) {
      if ( event.target.value.toString().length <= 2 ) {
        this.setState( { monthValue: event.target.value } )
      }
    }
  }

  handleCVC = ( event ) => {
    const re = /^[0-9\b]+$/
    if ( event.target.value === '' || re.test( event.target.value ) ) {
      if ( event.target.value.toString().length <= 4 ) {
        this.setState( { cvcValue: event.target.value } )
      }
    }
  }

  /**
   * Render Component
   * @returns {jsx} .
   */
  render() {
    const {
      messages, cardError, monthError, yearError, securityCodeError, refCard
    } = this.props

    const mercadoPagoForm = (
      <div
        id="pay"
        style={{ display: 'none' }}
      >
        <input
          type="hidden"
          id="cardNumber"
          data-checkout="cardNumber"
          autoComplete="off"
        />
        <input
          type="hidden"
          id="cardholderName"
          data-checkout="cardholderName"
        />
        <input
          type="hidden"
          id="cardExpirationMonth"
          data-checkout="cardExpirationMonth"
          autoComplete="off"
        />
        <input
          type="hidden"
          id="cardExpirationYear"
          data-checkout="cardExpirationYear"
          autoComplete="off"
        />
        <input
          type="hidden"
          id="securityCode"
          data-checkout="securityCode"
          autoComplete="off"
        />
      </div>
    )
    let {
      cardValue,
      monthValue,
      yearValue,
      cvcValue
    } = this.state
    return (
      <div className={[ styles.ContainerFlex, styles.HeightContainer ].join( ' ' )}>
        <div className={styles.Container}>
          <div className={styles.Form}>
            <label
              htmlFor="card"
              className={styles.Inp}
            >
              <input
                ref={refCard[0]}
                id="card"
                data-checkout="cardNumber"
                type="text"
                placeholder="&nbsp;"
                value={cardValue}
                onChange={this.handleCardNumber}
              />
              <span className={styles.Label}>{messages.card}</span>
              <span className={styles.Border} />
            </label>
          </div>
          {cardError && (
            <label className={styles.Error}>{messages.cardError}</label>
          )}
          <div className={styles.DataCard}>
            <div className={styles.DataCardContainer}>
              <div className={styles.Form}>
                <label
                  htmlFor="month"
                  className={styles.Inp}
                >
                  <input
                    ref={refCard[1]}
                    id="month"
                    placeholder="&nbsp;"
                    value={monthValue}
                    onChange={this.handleMonth}
                  />
                  <span className={styles.Label}>{messages.month}</span>
                  <span className={styles.Border} />
                </label>
              </div>
              {monthError && (
                <label className={styles.Error}>{messages.monthError}</label>
              )}
            </div>
            <div className={styles.DataCardContainer}>
              <div className={styles.Form}>
                <label
                  htmlFor="year"
                  className={styles.Inp}
                >
                  <input
                    ref={refCard[2]}
                    id="year"
                    placeholder="&nbsp;"
                    value={yearValue}
                    onChange={this.handleYear}
                  />
                  <span className={styles.Label}>{messages.year}</span>
                  <span className={styles.Border} />
                </label>
              </div>
              {yearError && (
                <label className={styles.Error}>{messages.yearError}</label>
              )}
            </div>
          </div>
          <div className={styles.Form}>
            <label
              htmlFor="code"
              className={styles.Inp}
            >
              <input
                ref={refCard[3]}
                id="code"
                placeholder="&nbsp;"
                value={cvcValue}
                onChange={this.handleCVC}
              />
              <span className={styles.Label}>{messages.securityCode}</span>
              <span className={styles.Border} />
            </label>
          </div>
          {securityCodeError && (
            <label className={styles.Error}>{messages.securityCodeError}</label>
          )}
          {mercadoPagoForm}
        </div>
      </div>
    )
  }
}
