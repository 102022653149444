import React from 'react'
import styles from './LoaderSection.module.css'

/**
 * LoaderSection Component
 * @returns {void} .
 */
const LoaderSection = () => {
  return (
    <div className={styles.LdsRoller}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default LoaderSection
