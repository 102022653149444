import React from 'react'
import './App.css'
import {
  Switch,
  Route,
  BrowserRouter,
  Redirect
} from 'react-router-dom'
import CheckoutFromPaymentId from './Components/CheckoutFromQuerys/CheckoutFromPaymentId'

//paymentLinkKey: 'rwtynxib3z',
/**
 * example of customerData needed
 * for proccess a payment when the personalData component
 * will not be show
 */
const customerData = {}

/**
 * Main app
 * @returns {void} .
 */
const App = () => (

  <BrowserRouter>
    <Switch>
      <Route
        path="/"
        render={() => <h1>Esta liga ya no es valida, por favor contacta a tu agente de ventas moons.</h1>}
      />
    </Switch>
  </BrowserRouter>
)

export default App

