
/**
 * Conekta success response handler
 * @param {object} formData .
 * @param {function} paymentData .
 * @param {function} success .
 * @param {function} error .
 * @param {String} country .
 * @return {void} .
 */
const sendBack = async (formData, paymentData, success, error) => {
  const BasicAuthUsername = 'user'
  const BasicAuthPassword = 'secret'
  const auth = `${BasicAuthUsername}:${BasicAuthPassword}`
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`

  const input = {
    ...formData,
    ...paymentData
  }

  console.log(input)
  console.log(JSON.stringify(input))
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: authEncoded,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(input)
  }

  const urlCheckout = process.env.REACT_APP_LAMBDA_URL

  const response = await fetch(urlCheckout, requestOptions)
  const data = await response.json()
  data.name = formData.name
  console.log(data)

  if (response.ok) {
    success && success(data)
  } else {
    error && error(data)
  }
}

export default { sendBack }
