import React, { Component, createRef } from 'react'
import currency from 'currency.js'
import style from './ProductInformation.module.css'
import messages from './ProductInformation.messages'
import arrow from '../../../assets/images/svg/arrow_down.svg'

/**
 * ProductInformation Component
 * @returns {void} .
 */
class ProductInformation extends Component {

  constructor( props ) {
    super( props )
    this.state = {
      openDropDown: false
    }
    this.refImg = createRef( null )
  }

  openDropDown = () => {
    const open = !this.state.openDropDown
    this.refImg.current.style.transform = `rotate(${ open ? 0 : 180 }deg)`
    this.setState( { openDropDown: open } )
  }

  render() {
    const { openDropDown } = this.state
    const { productName,
         priceData,
         includes,
         hasDiscount,
         couponMobile,
         priceInstallments,
         discountReason,
         discountPercent,
         monthSelected,
         installmentsType
        } = this.props
    let priceShow = 0, discountShow = 0, totalShow = 0
    let couponDiscount = null
    let couponText = null
    let currencyUpper = null
    let couponComponent = null
    let discountComponent = null

    if ( priceData ) {
      const { price, discount, total, currencyData } = priceData
      currencyUpper = currencyData ? currencyData.toUpperCase() : ''
      priceShow = `$${ currency( price, { precision: 2 } ).format() } ${ currencyUpper }`
      discountShow = `- $${ currency( discount, { precision: 0 } ).format() } ${ currencyUpper }`
      totalShow = `$${ currency( total, { precision: 2 } ).format() } ${ currencyUpper }`

      let totalFinal = total

      if(priceInstallments) {
        totalFinal = priceInstallments
        totalShow = `$${ currency( totalFinal, { precision: 2 } ).format() } ${ currencyUpper }`
      }

      if(couponMobile) {
        couponDiscount = couponMobile.Discount;
        if(couponMobile.Discount_Type === 'percentage') {
          couponText = `Cupón con ${couponDiscount}% de descuento`
          totalFinal = (1 - (couponDiscount/100))*totalFinal
          console.log({totalFinal})
        }
        else {
          couponText = `Cupon con $${couponDiscount}.00 ${currencyUpper} de descuento`
          totalFinal -= couponDiscount;
        }
        totalShow = `$${ currency( totalFinal, { precision: 2 } ).format() } ${ currencyUpper }`
  
        couponComponent = (
          <div className={[ style.ItemPrice, style.ItemTotal ].join( ' ' )}>
            <div className={style.ItemDiscount}>
              {couponText}
            </div>
          </div>
        )
      }
      if(priceInstallments) {
        totalFinal = priceInstallments
        totalShow = `$${ currency( totalFinal, { precision: 2 } ).format() } ${ currencyUpper }`
      }

      if(monthSelected === 0 && installmentsType === 2 && discountPercent !== 0) {
        const porcentText = `${discountPercent}% de descuento: ${discountReason}`
        discountComponent = (
          <div className={[ style.ItemPrice, style.ItemTotal ].join( ' ' )}>
            <div className={style.ItemDiscount}>
              {porcentText}
            </div>
          </div>
        )
      }
    }

    return (
      <div className={style.InfoContainer}
        onClick={() => this.openDropDown()}>
        <div className={style.TitleResume}>
          <div className={style.TextTitle}>
            {messages.titleInfo}
          </div>
          <img
            ref={this.refImg}
            alt="flecha"
            src={arrow}
            style={{ transform: `rotate(${ openDropDown ? 0 : 180 }deg)` }}
          />
        </div>
        {openDropDown && <div className={style.ContainerResume}>
          <div className={style.DescriptionResume}>
            <p className={style.Bold}>{productName}</p>
            <p className={style.Bold}>{messages.include}</p>
            {includes.map( ( item, index ) =>
              <li key={index}>{item}</li>
            )}
          </div>
          <hr className={style.Separator}></hr>
          <div className={style.ContainerPrice}>
            {hasDiscount && ( <><div className={style.ItemPrice}>
              <div className={style.ItemTitle}>
                {messages.price}
              </div>
              <div className={style.ItemAmount}>
                {priceShow}
              </div>
            </div> &&
              <div className={style.ItemPrice}>
                <div className={style.ItemTitle}>
                  {messages.discount}
                </div>
                <div className={[ style.ItemAmount, style.ItemDiscount ].join( ' ' )}>
                  {discountShow}
                </div>
              </div></> )}
            {discountComponent}
            <div className={[ style.ItemPrice, style.ItemTotal ].join( ' ' )}>
              <div className={style.ItemTitle}>
                {messages.total}
              </div>
              <div className={style.ItemAmount}>
                {totalShow}
              </div>
            </div>

          </div>
        </div>}

      </div>
    )
  }
}

export default ProductInformation
