import React, { Component } from 'react'
import currency from 'currency.js'
import style from './ProductInfoDesktop.module.css'
import messages from './ProductInfoDesktop.messages'

/**
 * ProductInformation Component
 * @returns {void} .
 */
class ProductInfoDesktop extends Component {

  render() {
    const { productName, 
           priceData,
           includes,
           hasDiscount,
           coupon,
           priceInstallments,
           discountReason,
           discountPercent,
           monthSelected,
           installmentsType
          } = this.props
    let priceShow = 0, discountShow = 0, totalShow = 0
    let couponDiscount = null
    let couponText = null
    let currencyUpper = null
    console.log({coupon})
    let couponComponent = null
    let discountComponent = null
    if ( priceData ) {
      const { price, discount, total, currencyData } = priceData
      console.log({priceData})
      currencyUpper = currencyData ? currencyData.toUpperCase() : ''
      priceShow = `$${ currency( price, { precision: 2 } ).format() } ${ currencyUpper }`
      discountShow = `- $${ currency( discount, { precision: 0 } ).format() } ${ currencyUpper }`
      totalShow = `$${ currency( total, { precision: 2 } ).format() } ${ currencyUpper }`

      let totalFinal = total

      if(priceInstallments) {
        totalFinal = priceInstallments
        totalShow = `$${ currency( totalFinal, { precision: 2 } ).format() } ${ currencyUpper }`
      }

      if(coupon) {
        couponDiscount = coupon.Discount;
        if(coupon.Discount_Type === 'percentage') {
          couponText = `Cupón con ${couponDiscount}% de descuento`
          totalFinal = (1 - (couponDiscount/100))*totalFinal
          console.log({totalFinal})
        }
        else {
          couponText = `Cupon con $${couponDiscount}.00 ${currencyUpper} de descuento`
          totalFinal -= couponDiscount;
        }
        totalShow = `$${ currency( totalFinal, { precision: 2 } ).format() } ${ currencyUpper }`
  
        couponComponent = (
          <div className={[ style.ItemPrice, style.ItemTotal ].join( ' ' )}>
            <div className={style.ItemDiscount}>
              {couponText}
            </div>
          </div>
        )
      }
      
      if(priceInstallments) {
        totalFinal = priceInstallments
        totalShow = `$${ currency( totalFinal, { precision: 2 } ).format() } ${ currencyUpper }`        
      }

      if(monthSelected === 0 && installmentsType === 2 && discountPercent !== 0) {
        const porcentText = `${discountPercent}% de descuento: ${discountReason}`
        discountComponent = (
          <div className={[ style.ItemPrice, style.ItemTotal ].join( ' ' )}>
            <div className={style.ItemDiscount}>
              {porcentText}
            </div>
          </div>
        )
      }
    }
    
    return (
      <div className={style.InfoContainer}>
        <div className={style.TitleResume}>
          <div className={style.TextTitle}>
            {messages.titleInfo}
          </div>
        </div>
        <div className={style.ContainerResume}>
          <div className={style.DescriptionResume}>
            <p className={style.Bold}>{productName}</p>
            <p className={style.Bold}>{messages.include}</p>
            {includes && includes.map( ( item, index ) =>
              <li key={index}>{item}</li>
            )}
          </div>
          <hr className={style.Separator}></hr>
          <div className={style.ContainerPrice}>
            {hasDiscount &&
              ( <><div className={style.ItemPrice}>
                <div className={style.ItemTitle}>
                  {messages.price}
                </div>
                <div className={style.ItemAmount}>
                  {priceShow}
                </div>
              </div>
                <div className={style.ItemPrice}>
                  <div className={style.ItemTitle}>
                    {messages.discount}
                  </div>
                  <div className={[ style.ItemAmount, style.ItemDiscount ].join( ' ' )}>
                    {discountShow}
                  </div>
                </div></> )}
            {discountComponent}
            <div className={[ style.ItemPrice, style.ItemTotal ].join( ' ' )}>
              <div className={style.ItemTitle}>
                {messages.total}
              </div>
              <div className={style.ItemAmount}>
                {totalShow}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProductInfoDesktop
