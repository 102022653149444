import React, {useState} from 'react'
import FormGroup from '../FormGroup/FormGroup'
import style from './Coupon.module.css'
import * as _ from 'underscore';
import Services from '../../../../Apis/Back4AppServices';
/**
 * PersonalData Componenet
 * @param {*} props - .
 * @param {boolean}   errorCoupon - true if error in name input
 * @param {String}    couponTextLabel - text for label name input
 * @returns {void} .
 */
const Coupon = (props) => {
  const {
    couponTextLabel,
    refCoupon,
    validCoupon,
    productKeyName
  } = props
  const [error,setError] = useState(false)
  const errorText = 'Cupón invalido'
  const handleInputCoupon = async (event) => {
    event.persist();
    await handleWithThrottle(event)
  }
  const handleWithThrottle = _.throttle(async (event) => {
    let code = event.target.value;
    let coupon = await Services.getCoupon(code,productKeyName);
    console.log({coupon})
    if(!coupon) {
      setError(true)
    }
    else {
      setError(false)
    }
    if (window.innerWidth < 700) {
      validCoupon(null, coupon)
    }
    else {
      validCoupon(coupon)
    }
  },500,{leading:false});

  return (
    <div className={style.CouponContainer}>
      <FormGroup
        refInput={refCoupon}
        idInput="coupon"
        error={error}
        errorText={errorText}
        typeInput="text"
        textLabel={couponTextLabel}
        onChange={handleInputCoupon}
      />
    </div>
  )
}

export default Coupon
