const messages = {
  card: 'Número de Tarjeta',
  month: 'Mes (MM)',
  year: 'Año (YYYY)',
  securityCode: 'CVV',
  cardError: 'Ingresa una tarjeta válida',
  yearError: 'Deben ser 4 digitos',
  monthError: 'Deben ser 2 digitos',
  securityCodeError: 'Deben ser 3 o 4 digitos',
}

export default messages
