import React, { useRef } from 'react'
import style from '../CheckoutForm/CheckoutForm.module.css'

/**
 * @param {Function} handlePaymentMethodSelection - function to handle the onClick event
 * @param {String} cardPaymentMethod - card payment method (stripe, mercadoPago)
 * @param {String} cashPaymentMethod - cash payment method (mercadoPago, payU)
 * @param {String} cardButtonText - text in the label for the card button type
 * @param {String} cashButtonText - text in the label for the cash button type
 * @returns {React.Component} - Returns PaymentSelectionComponent
 */
const PaymentSelection = ( {
  handlePaymentMethodSelection,
  cardPaymentMethod,
  cashPaymentMethod,
  cardButtonText,
  cashButtonText
} ) => {
  const refCard = useRef()
  const refCash = useRef()

  /**
   *
   * @param {*} event  - on click event
   * @returns {void} .
   */
  const onClick = ( event ) => {
    event.preventDefault()
    const { target: { id } } = event
    if ( id === 'cardPaymentMethodButton' ) {
      handlePaymentMethodSelection( cardPaymentMethod )
      addClass( refCard.current, style.Active )
      removeClass( refCash.current, style.Active )
    } else {
      handlePaymentMethodSelection( cashPaymentMethod )
      addClass(refCash.current, style.Active )
      removeClass( refCard.current, style.Active )
    }
  }

  const addClass = ( el, myClass ) => {
    el.classList.add( myClass );
  }

  const removeClass = ( el, myClass ) => {
    el.classList.remove( myClass );
  }

  let cardButton = null
  let cashButton = null
  if ( cardPaymentMethod ) {
    cardButton = (
      <button
        ref={refCard}
        className={[style.ActionButton, style.Active].join(' ')}
        type="button"
        id="cardPaymentMethodButton"
        onClick={onClick}
      >
        {cardButtonText}
      </button>
    )
  }
  if ( cashPaymentMethod ) {
    cashButton = (
      <button
        ref={refCash}
        className={style.ActionButton}
        type="button"
        id="cashPaymentMethodButton"
        onClick={onClick}
      >
        {cashButtonText}
      </button>
    )
  }

  return (
    <div className={style.ContainerButton}>
      {cardButton}
      {cashButton}
    </div>
  )
}

export default PaymentSelection
