import React from 'react'

const messages = {
  404 : '404 not found '
}
const ErrorQuery = () => {
  return (
    <div>
      <h1>{messages[404]}</h1>
    </div>
  )
}

export default ErrorQuery