import React from 'react'
import styles from './Success.module.css'
import logo from '../../../assets/images/svg/moons.svg'

const logoComponent = (
  <div className={styles.Logo}>
      <img
        alt="logo"
        src={logo}
      />
    </div>
)

/**
 * Success Component
 * @param {String} name - customer name
 * @param {String} moonsEmail - email of moons
 * @returns {React.Component} Success .
 */
const Success = ({
  name,
  moonsEmail,
  mercadoPagoOxxoUrl,
  conektaOxxoUrl
}) => {
  const position = 0
  const title = `¡Gracias por tu compra, ${name}!`
  const styleSpan = {
    color: '#0752f9',
    fontWeight: 'bold'
  }

  /**
   *
   * @param {*} event .
   * @returns {void} .
   */
  const handleClick = (event) => {
    event.preventDefault()
    if (mercadoPagoOxxoUrl) {
      window.open(mercadoPagoOxxoUrl, '_blank')
    } else if (conektaOxxoUrl) {
      window.open(conektaOxxoUrl, '_blank')
    }
  }
  const subtitle = 'Recibiras tu compra en menos de 72 horas. Si tienes dudas, escribenos a  '
  const emailSpan = <span style={styleSpan}>{moonsEmail}</span>
  const getOxxoCodeText = 'Para obtener tu codigo de oxxo da click en el boton de abajo'
  const oxxoCodeButtonText = 'Codigo'
  let getOxxoCode = null
  let paidWithCard = null
  let getOxxoCodeButton = null
  if (mercadoPagoOxxoUrl || conektaOxxoUrl) {
    getOxxoCode = <h2>{getOxxoCodeText}</h2>
    getOxxoCodeButton = (
      <button
        type="button"
        className={styles.codeButton}
        onClick={handleClick}
      >
        {oxxoCodeButtonText}
      </button>
    )
  } else {
    paidWithCard = (
      <h2>
        {/* {subtitle} */}
        {emailSpan}
      </h2>
    )
  }
  console.log(moonsEmail)
  window.scrollTo(position, position)
  return (
    <div className={styles.success}>
      {logoComponent}
      <h1>{title}</h1>
      {paidWithCard}
      {getOxxoCode}
      {getOxxoCodeButton}
    </div>
  )
}

export default Success
