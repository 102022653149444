/* eslint-disable max-statements */
/* eslint-disable no-magic-numbers */
import React, {createRef} from 'react'
import styles from './CheckoutForm.module.css'
import PersonalData from './PersonalData/PersonalData'
import Address from './Address/Address'
import Coupon from './Coupon/Coupon'
import LoaderSection from '../../LoaderSection/LoaderSection'
import FormCard from './FormCard/FormCard'
import messageCard from './FormCard/FormCard.messages'
import checkoutBack from '../../../Apis/CheckoutBack'
import Installments from './Installments/Installments'
import InstallmentsTwo from './InstallmentsTwo/InstallmentsTwo'

/**
 * @param {String} country .
 * @param {String} customerId - customer id of the customer if the product is the moons type
 * @param {String} productKeyName - id of the product
 * @param {String} paymentKeyName - id of the payment link
 * @param {Function} handleSuccess - function to do when the payment intent was succeded
 * @param {Function} handleError - function to do when the payment intent conclude with an error
 * @param {Object} data - data with installments
 * @param {Boolean} showPersonalData - true for show PersonalData Component
 * @param {Boolean} showAddress - true for show Address Component
 * @param {Boolean} showCoupon - true for show Coupon Component
 * @param {String} paymentMethod - payment method to use (card, oxxo)
 * @param {Boolean} isAdvance - true if the the customer will pay the and advance of the product
 * @param {Object} customerData - Object with the customer data to process the payment if the input data is not required
 * @returns {void} .
 */
class CheckoutForm extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {
      errorName: false,
      errorEmail: false,
      errorNumber: false,
      errorStreet: false,
      errorColony: false,
      errorState: false,
      errorCoupon: false,
      loader: false,
      errorData: false,
      cardError: false,
      monthError: false,
      yearError: false,
      securityCodeError: false,
      conektaId: null,
      mercadoPagoId: null,
      cardType: null,
      paymentTypeId: null,
      stripeId: null,
      cantInstallments: 0
    }

    this.nameTextLabel = 'Tu nombre y apellido'
    this.emailTextLabel = 'Correo electrónico'
    this.numberTextLabel = 'Teléfono de contacto'
    this.streetTextLabel = 'Calle'
    this.colonyTextLabel = 'Colonia'
    this.stateTextLabel = 'Estado'
    this.couponTextLabel = 'Si tienes un cupón ingrésalo '
    this.payButtonText = 'Pagar'

    window.Conekta.setPublicKey(process.env.REACT_APP_CONEKTA_KEY)
    window.Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_KEY)
    window.Mercadopago.setPublishableKey(process.env.REACT_APP_MERCADO_PAGO_KEY)

    this.refCard = this.dinamicRef(4)
    this.refCoupon = createRef(null)
    this.refPersonalData = this.dinamicRef(3)
    this.refAddress = this.dinamicRef(3)
    this.refLoader = createRef(null)
    this.refForm = createRef(null)
  }

  dinamicRef = (total) => {
    let refs = [];
    for (let i = 0; i < total; i++) {
        refs[i] = createRef();
    }
    return refs
}

  /**
   * Validate the name
   * @param {String} name - value on the input name
   * @returns {*} .
   */
  validateInputName = name => {
    if (name === null || name === '') {
      this.errorTextName = 'El nombre no puede estar vacio!'
      this.setState({ errorName: true })
      return false
    }
    if (name.split(' ').length <= 1) {
      this.errorTextName = 'Necesitamos al menos un apellido'
      this.setState({ errorName: true })
      return false
    }

    this.setState({ errorName: false })
    return true
  }

  /**
   * Validate the email
   * @param {String} email - value on the input email
   * @returns {*} .
   */
  validateInputEmail = email => {
    const re = /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!re.test(email.toString().toLowerCase())) {
      this.errorTextEmail = 'Error en el correo, verificalo!'
      this.setState({ errorEmail: true })
      return false
    }

    this.setState({ errorEmail: false })
    return true
  }

  /**
   * Validate the pone
   * @param {String} phone - value on the input phone
   * @returns {*} .
   */
  validateInputPhone = phone => {
    if (phone.toString().length < 10) {
      this.errorTextNumber = 'El número debe de tener 10 digitos.'
      this.setState({ errorNumber: true })
      return false
    }

    this.setState({ errorNumber: false })
    return true
  }

  /**
   * Validate the street
   * @param {String} value - value on the street input
   * @returns {*} .
   */
  validateInputStreet = value => {
    if (value === '') {
      this.setState({ errorStreet: true })
      return false
    }
    this.setState({ errorStreet: false })
    return true
  }

  /**
   * Validate the colony
   * @param {String} value - value on the colony input
   * @returns {*} .
   */
  validateInputColony = value => {
    if (value === '') {
      this.setState({ errorColony: true })
      return false
    }
    this.setState({ errorColony: false })
    return true
  }

  /**
   * Validate the state
   * @param {String} value - value on the state input
   * @returns {*} .
   */
  validateInputState = value => {
    if (value === '') {
      this.setState({ errorState: true })
      return false
    }
    this.setState({ errorState: false })
    return true
  }

  /**
   * validate the personal data
   * @param {string} name - name
   * @param {string} email - email
   * @param {string} number - number
   * @return {void} .
   */
  validatePersonalData = (name, email, number) => {
    const validName = this.validateInputName(name)
    const validEmail = this.validateInputEmail(email)
    const validNumber = this.validateInputPhone(number)
    if (validName && validEmail && validNumber) {
      return true
    }
    return false
  }

  /**
   * validate the address
   * @param {string} street - street
   * @param {string} colony - colony
   * @param {string} state - state
   * @return {void} .
   */
  validateAddress = (street, colony, state) => {
    const validStreet = this.validateInputStreet(street)
    const validColony = this.validateInputColony(colony)
    const validState = this.validateInputState(state)
    if (validStreet && validColony && validState) {
      return true
    }
    return false
  }

  validateExpiry = (month, year) => {
    const monthInt = month ? parseInt(month) : 0
    const yearInt = year ? parseInt(year) : 0

    let validateMonth = true,  validateYear = true;
    const date = new Date();
    const currentYear = date.getFullYear()
    const currentMonth = date.getMonth()
    if (year.toString().length < 4 || yearInt < currentYear) {
      validateYear = false
    }
    if((yearInt === currentYear && monthInt < currentMonth) || monthInt > 12 || monthInt === 0) {
      validateMonth = false
    }
    return {validateMonth, validateYear}
  }

  /**
   * Validate Card.
   * @param {string} card .
   * @param {string} month .
   * @param {string} year .
   * @param {string} code .
   * @returns {void} .
   */
  validateFormCard = (card, month, year, code) => {
    const { cardError, securityCodeError, monthError, yearError } = this.state

    const validateCard = window.Stripe.card.validateCardNumber(card)
    const validateCVC = window.Stripe.card.validateCVC(code)
    const {validateMonth, validateYear} = this.validateExpiry(month, year)
    if (cardError === validateCard) this.setState({ cardError: !validateCard })
    if (securityCodeError === validateCVC) this.setState({ securityCodeError: !validateCVC })
    if (monthError === validateMonth) this.setState({ monthError: !validateMonth })
    if (yearError === validateYear) this.setState({ yearError: !validateYear })

    if (validateCard && validateCVC && validateYear && validateMonth) {
      return true
    }
    return false
  }


  /**
   * Main function to handle the checkout payment process
   * @param {Event} event - onSubmit event
   * @return {void} .
   */
  handleSubmit = async (event) => {
    event.preventDefault()
    window.scrollTo(0, 0)
    this.refForm.current.classList.add(styles.DisplayNone)
    this.refLoader.current.classList.remove(styles.DisplayNone)

    const {
      showPersonalData,
      showAddress,
      showCoupon,
      paymentMethod,
      country,
    } = this.props
    let name = null
    let email = null
    let number = null
    let street = null
    let colony = null
    let state = null
    let coupon = null
    let card = null
    let year = null
    let month = null
    let code = null

    if (showPersonalData) {
      name = this.refPersonalData[0].current.value
      email = this.refPersonalData[1].current.value
      number = parseInt(this.refPersonalData[2].current.value, 10)
      if (!this.validatePersonalData(name, email, number)) {
        this.refForm.current.classList.remove(styles.DisplayNone)
        this.refLoader.current.classList.add(styles.DisplayNone)
        return
      }
    } else {
      const { customerData } = this.props
      name = customerData.name
      number = customerData.phone
      email = customerData.email
    }
    if (showAddress) {
      street = this.refAddress[0].current.value
      colony = this.refAddress[1].current.value
      state = this.refAddress[2].current.value
      if (!this.validateAddress(street, colony, state)) {
        this.refForm.current.classList.remove(styles.DisplayNone)
        this.refLoader.current.classList.add(styles.DisplayNone)
        return
      }
    }
    if (showCoupon) {
      coupon = this.refCoupon.current.value
      console.log({ coupon })
    }

    if (paymentMethod === 'card') {
      card = this.refCard[0].current.value
      month = this.refCard[1].current.value
      year = this.refCard[2].current.value
      code = this.refCard[3].current.value
      if (!this.validateFormCard(card, month, year, code)) {
        this.refForm.current.classList.remove(styles.DisplayNone)
        this.refLoader.current.classList.add(styles.DisplayNone)
        return
      }
    }

    const formData = {
      name,
      email,
      number,
      address: {
        street,
        colony,
        state,
      },
      coupon,
      country
    }

    const cardData = {
      card,
      year,
      month,
      code,
    }

    if (paymentMethod === 'card') {
      await this.generateAllKey(formData, cardData)
    } else {
      this.sendToBack(formData)
    }
  }

  /**
   * @param {Object} formData .
   * @returns {void} .
   */
  sendToBack = (formData) => {
    const { handleSuccess, handleError, data } = this.props
    const {stripeId, conektaId, mercadoPagoId, cantInstallments} = this.state
    const { installmentsType } = data
    console.log({stripeId,conektaId,mercadoPagoId})
    const paymentData = {
      conektaId: conektaId,
      stripeId: stripeId,
      mercadoPagoId: mercadoPagoId,
      paymentMethodType: this.props.paymentMethod,
      productKeyName: this.props.productKeyName,
      paymentLinkKey: this.props.paymentKeyName,
      isAdvance: this.props.isAdvance,
      customerId: this.props.customerId,
      cardType: this.state.cardType,
      paymentTypeId: this.state.paymentTypeId,
      installments: cantInstallments,
      transactionType: this.props.paymentKeyName ? 'paymentLink' : 'product',
      discountPrice: (cantInstallments === 0 && installmentsType === 2)
    }

    checkoutBack.sendBack(
      formData,
      paymentData,
      handleSuccess,
      handleError
    )

  }

  /**
   * Create the stripe token
   * @param {*} stripeData - data to create the tokne
   * @returns {void} .
   */
  createStripeToken = async (stripeData,mercadoPagoData,formData,conektaData) => {
    await window.Stripe.card.createToken(stripeData, (status, response) => {
      console.log({ statusStripe: status })
      if (response.error) {
        this.setState({ stripeId: null })
      } else {
        console.log({ responseStripe: response })
        this.setState({ stripeId: response.id })
      }

      if(conektaData) {
        this.createConektaToken(conektaData,mercadoPagoData,formData)
      }
      else {
        this.createMercadoPagoToken(mercadoPagoData,formData)
      }
    })
  }

  /**
   * Create the mercadoPagoToken
   * @param {String} card - the card value
   * @param {String} name - the name owner
   * @param {Array} date -  the expiration date [month,year]
   * @param {String} code - the security code
   * @returns {void} .
   */
  createMercadoPagoToken = async (mercadoPagoData, formData) => {
    const {card, month, year, code } = mercadoPagoData.cardData
    const cardNumber = document.getElementById('cardNumber');
    const cardholderName = document.getElementById('cardholderName')
    const cardExpirationMonth = document.getElementById('cardExpirationMonth')
    const cardExpirationYear = document.getElementById('cardExpirationYear')
    const securityCode = document.getElementById('securityCode')

    cardNumber.value = card
    cardholderName.value = mercadoPagoData.name
    cardExpirationMonth.value = month
    cardExpirationYear.value = year
    securityCode.value = code

    if (card.length >= 6) {
      const bin = card.substring(0, 6)
      window.Mercadopago.getPaymentMethod({ bin },
        (statusPaymentMethodId, responsePaymentMethodId) => {
          if (statusPaymentMethodId === 200) {
            const form = document.querySelector('#pay')
            console.log({ responsePaymentMethodId })
            if(responsePaymentMethodId === null || responsePaymentMethodId === undefined ) {
              this.setState({ cardType: null, paymentTypeId: null, mercadoPagoId : null })
              this.sendToBack(formData)
            }
            else if (responsePaymentMethodId.length === 0 ){
              this.setState({ cardType: null, paymentTypeId: null, mercadoPagoId : null })
              this.sendToBack(formData)
            }
            else {
              this.setState({ cardType: responsePaymentMethodId[0].id, paymentTypeId: responsePaymentMethodId[0].payment_type_id })
                window.Mercadopago.createToken(form, (status, response) => {
                console.log({ mercadoPagoStatus: status })
                if (status === 200 || status === 201) {
                  console.log({ mercadoPagoResponse: response })
                  this.setState({ mercadoPagoId: response.id })
                } else {
                  this.setState({ mercadoPagoId: null })
                }
                this.sendToBack(formData)
              })
            } 
          } else {
            this.setState({ cardType: null })
            this.setState({ mercadoPagoId: null })
            this.sendToBack(formData)
          }
        })
    }
  }

  /**
   * Create the conekta token
   * @param {Object} conektaData - the object containing the data to create the token
   * @returns {void} .
   */
  createConektaToken = async (conektaData,mercadoPagoData,formdData) => {
    await window.Conekta.Token.create(conektaData,
      (successEvent) => {
        console.log({ eventoConekta: successEvent })
        this.setState({ conektaId: successEvent.id })
        this.createMercadoPagoToken(mercadoPagoData,formdData)
      },
      (errorEvent) => {
        console.log({ eventoConekta: errorEvent })
        this.setState({ conektaId: null })
        this.createMercadoPagoToken(mercadoPagoData,formdData)
      })
  }

  /**
   * Generate all the key tokens to send to the back
   * @param {object} formData .
   * @param {object} cardData .
   * @return {void} .
   */
  generateAllKey = async (formData, cardData) => {

    const cardInfo = {
      name: formData.name,
      number: cardData.card,
      cvc: cardData.code,
      exp_month: cardData.month,
      exp_year: cardData.year
    }

    const conektaData = {
      card: {
        ...cardInfo
      }
    }
    const stripeData = {
      ...cardInfo,
      address_country: formData.country
    }
    const { name } = formData
    const mercadoPagoData = {
      cardData,
      name
    }
    if (formData.country === 'México') {
      this.createStripeToken(stripeData,mercadoPagoData,formData,conektaData)
    } else {
      this.createStripeToken(stripeData,mercadoPagoData,formData,null)
    }
  }

  /**
   * set the number of installments to pay
   * @param {integer} cant - number of installments
   * @returns {void} .
   */
  setInstallments = (cant) => {
    const { installmentsType } = this.props.data
    this.setState({ cantInstallments: cant })
    this.props.setPrice(cant, installmentsType)
  }

  /**
   * @returns {void} .
   */
  render() {
    const {
      showPersonalData,
      showAddress,
      showCoupon,
      paymentMethod,
      data,
      validCoupon,
      isAdvance
    } = this.props

    let installments = 0, finalPrice = 0, installmentsType = 1, minInstallmentsAmount = 0
    if (data) {
      installments = data.installments
      finalPrice = data.finalPrice
      installmentsType = data.installmentsType
      minInstallmentsAmount = data.minInstallmentsAmount
    }

    const {
      errorName,
      errorEmail,
      errorNumber,
      errorStreet,
      errorColony,
      errorState,
      errorCoupon,
      cardError,
      yearError,
      monthError,
      securityCodeError,
    } = this.state

    let personalDataComponent = null
    let addressComponent = null
    let couponComponent = null
    let installmentsSelectionComponent = null
    let paymentMethodComponent = null
    this.payButtonText = paymentMethod === 'card' ? 'Pagar' : 'Obtener Referencia'

    if (showPersonalData) {
      personalDataComponent = (
        <PersonalData
          errorName={errorName}
          errorEmail={errorEmail}
          errorNumber={errorNumber}
          nameTextLabel={this.nameTextLabel}
          emailTextLabel={this.emailTextLabel}
          numberTextLabel={this.numberTextLabel}
          refPersonalData={this.refPersonalData}
        />
      )
    }
    if (showAddress) {
      addressComponent = (
        <Address
          errorStreet={errorStreet}
          errorColony={errorColony}
          errorState={errorState}
          streetTextLabel={this.streetTextLabel}
          colonyTextLabel={this.colonyTextLabel}
          stateTextLabel={this.stateTextLabel}
          refAddress={this.refAddress}
        />
      )
    }
    if (showCoupon) {
      couponComponent = (
        <Coupon
          productKeyName={this.props.productKeyName}
          validCoupon={validCoupon}
          errorCoupon={errorCoupon}
          couponTextLabel={this.couponTextLabel}
          refCoupon={this.refCoupon}
        />
      )
    }

    if (paymentMethod === 'card') {
      paymentMethodComponent = (
        <FormCard
          messages={messageCard}
          cardError={cardError}
          yearError={yearError}
          monthError={monthError}
          securityCodeError={securityCodeError}
          refCard={this.refCard}
        />
      )
    }
    
    if (installments && finalPrice && finalPrice > minInstallmentsAmount
       && !isAdvance && paymentMethod === 'card') {
      console.log({finalPrice})
      installmentsSelectionComponent = installmentsType === 1 ? (
        <Installments
          setInstallments={this.setInstallments}
          price={finalPrice}
        />
      ): (
        <InstallmentsTwo
          setInstallments={this.setInstallments}
          discount={data.discountPrice}
          finalPrice={data.finalPrice}
        />
      )
    }
    return (
      <div className={styles.Container}>
        <div
          className={[
            styles.FormContainer,
            styles.DisplayNone,
            styles.CenterLoader
          ].join(' ')}
          id="loader"
          ref={this.refLoader}
        >
          <LoaderSection />
        </div>
        <form
          className={styles.FormContainer}
          onSubmit={this.handleSubmit}
          id="form"
          ref={this.refForm}
        >
          {personalDataComponent}
          {addressComponent}
          {couponComponent}
          {paymentMethodComponent}
          {installmentsSelectionComponent}
          <div className={styles.FormSubmitButton}>
            <button
              className={styles.SubmitButton}
              type="submit"
            >
              {this.payButtonText}
            </button>
          </div>
        </form>
      </div>
    )
  }
}

export default CheckoutForm
