import React from 'react'
import styles from './PersonalData.module.css'
import FormGroup from '../FormGroup/FormGroup'

/**
 * PersonalData Componenet
 * @param {*} props - .
 * @param {boolean}   errorName - true if error in name input
 * @param {boolean}   errorEmail - true if error in email input
 * @param {boolean}   errorNumber - true if error in number input
 * @param {String}    nameTextLabel - text for label name input
 * @param {String}    emailTextLabel - text for email label input
 * @param {String}    numberTextLabel - text for number label input
 * @returns {void} .
 */
const PersonalData = (props) => {
  const {
    errorName,
    errorEmail,
    errorNumber,
    nameTextLabel,
    emailTextLabel,
    numberTextLabel,
    refPersonalData
  } = props
  const errorTextName = 'Error en el nombre, verificalo'
  const errorTextEmail = 'Error en el correo, verificalo'
  const errorTextNumber = 'Error en el numero, verificalo'
  return (
    <div className={styles.PersonalDataContainer}>
      <FormGroup
        idInput="name"
        error={errorName}
        errorText={errorTextName}
        typeInput="text"
        textLabel={nameTextLabel}
        refInput={refPersonalData[0]}
      />
      <FormGroup
        idInput="email"
        error={errorEmail && errorEmail !== ''}
        errorText={errorTextEmail}
        typeInput="email"
        textLabel={emailTextLabel}
        refInput={refPersonalData[1]}
      />
      <FormGroup
        idInput="number"
        error={errorNumber}
        errorText={errorTextNumber}
        typeInput="number"
        textLabel={numberTextLabel}
        refInput={refPersonalData[2]}
      />
    </div>
  )
}

export default PersonalData
