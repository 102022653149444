import React, { Component, createRef } from 'react'
import currency from 'currency.js'
import styles from './Installments.module.css'
import messages from './Installments.messages'
import arrow from '../../../../assets/images/svg/arrow_down.svg'


/**
 * Installments Component
 * @returns {React.Component} Installments .
 */
class Installments extends Component {
  /**
   * Constructor.
   * @param {object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)

    this.state = {
      openDropDown: false,
      currentTopic: {
        text: `Un solo pago de $${currency(props.price).format()} MXN`
      }
    }

    this.refImg = createRef(null)
  }

  OpenDropDown = () => {
    const open = !this.state.openDropDown
    this.refImg.current.style.transform = `rotate(${open ? 0 : 180}deg)`
    this.setState({ openDropDown: open })
  }

  /**
   * Change value selector
   * @param {object} item .
   * @param {object} title .
   * @returns {React.Component} Reference .
   */
  changeValue = (item, title) => {
    this.setState({ currentTopic: { text: title } })
    this.props.setInstallments(item.value)
  }

  /**
   * Render.
   * @param {string} id .
   * @returns {void} .
   */
  render() {
    const { openDropDown, currentTopic } = this.state
    const { price } = this.props
    const selector = (
      <div className={styles.ThemesMobileContainer}>
        <div
          className={styles.TopicSelector}
          onClick={() => this.OpenDropDown()}
          role="button"
          tabIndex="0"
        >
          <div className={styles.TextTema}>
            {currentTopic.text}
            <img
              ref={this.refImg}
              alt="flecha"
              src={arrow}
              style={{ transform: `rotate(${openDropDown ? 0 : 180}deg)` }}
            />
          </div>
          {openDropDown && (
            <ul>
              {messages.installmentsType.map((item, index) => {
                const TotalPrice = (item.percent/100 + 1) * price
                const cantXMonth = item.value ? currency(TotalPrice / item.value).format() : 0
                const title = item.value ? item.text.replace('{price}', `$${cantXMonth}`) 
                            : item.text.replace('{priceTotal}', `$${currency(price).format()}`) 
        
                return (
                  <li
                    key={item.id}
                    className={styles.Items}
                    onClick={() => this.changeValue(item, title)}
                    role="menuitem"
                  >
                    {title}
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    )

    return (
      <div className={styles.InstallmentsContainer}>
        {selector}
      </div>
    )
  }
}

export default Installments
