/* eslint-disable react/no-did-mount-set-state */
import React from 'react'
import { withRouter } from 'react-router-dom'
import CheckoutForm from './CheckoutForm/CheckoutForm'
import PaymentSelection from './PaymentSelection/PaymentSelection'
import Error from './Error/Error'
import Success from './Success/Success'
import style from './Checkout.module.css'
import ProductInformation from './ProductInformation/ProductInformation'
import Services from '../../Apis/Back4AppServices'
import logo from '../../assets/images/svg/moons.svg'
import ProductInfoDesktop from './ProductInfoDesktop/ProductInfoDesktop'

/**
 * Checkout Component for Moons
 * @param {String} productKeyName - id of the product to sell
 * @param {String} paymentKeyName - id of the paymentLink given to a customer
 * @param {bool} showPersonalData - show the PersonalData component
 * @param {bool} showAddress - show the Address component
 * @param {bool} showCoupon - show the coupon component
 * @param {bool} showProductInformation - show the product information
 * @param {String} cardPaymentMethod - the payment method for cards
 * @param {String} cashPaymentMethod - the payment method for cash
 * @param {Object} customerData - object with the customer data required if personalData component is false
 * @param {Boolean} isAdvance - true if the payment will be an advance of the total price of the product
 * @param {String} customerId - the id of the customer of moons
 * @param {String} country - the country
 * @returns {React.Component} Checkout .
 */
class Checkout extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor( props ) {
    super( props )
    this.state = {
      error: false,
      success: false,
      displayForm: true,
      responseError: null,
      conektaOxxoUrl: null,
      mercadoPagoOxxoUrl: null,
      data: {},
      paymentMethod: 'card',
      coupon: null,
      couponMobile: null,
      priceInstallments: 0,
      monthSelected: 0
    }
    this.pricePatient = 0
    this.priceProduct = 0
    this.moonsMail = 'hola@mymoons.mx'
    this.data = null
    this.productName = null
    this.propsProduct = {}
  }

  componentDidMount = async () => {
    await this.getProductInformation()
    console.log( "data", this.state.data )
  }

  getProductInformation = async () => {
    const { productKeyName, paymentKeyName, customerId, isAdvance } = this.props
    console.log( { productKeyName, paymentKeyName, customerId } )
    let data = null
    if ( productKeyName ) {
      data = await Services.getProductByKeyName( productKeyName )
      console.log( data )
    }
    if ( paymentKeyName ) {
      data = await Services.getPaymentLinkByKeyName( paymentKeyName )
    }
    this.priceProduct = data.finalPrice
    this.nameProduct = data.name
    if ( customerId && data.type === 'moons') {
      let patient = await Services.getPatientByCustomerId( customerId )
      this.patientName =  patient.Patient_Name      
      this.pricePatient = patient.Final_Price

      if (patient.Payment_Status === 'No Payment') {
        if (this.pricePatient < this.priceProduct) {
          data.finalPrice = this.pricePatient
        }

        if (isAdvance && data.price) { //anticipo
          data.finalPrice = data.price
        }
      } else if (patient.Payment_Status === 'Partial Payment' && patient.Paid_Amount) {
          const diff = this.pricePatient - patient.Paid_Amount
          if ( diff > 0 ) {
           data.finalPrice = diff
          } else {
            this.handleError({
              message: {
                message: 'El precio pagado es mayor o igual al que se intenta cobrar'
              }
            })
          }
      }
    }
    this.discountPercent = data.discountPrice
    this.finalPrice = data.finalPrice

    this.propsProduct = this.hasDiscount( data )

    this.setState( { data: data } )
  }

  validCoupon = (coupon,couponMobile) => {
    this.setState({coupon,couponMobile})
  }

  arrayPercentInstallments = () => {
      const percetXMonth = {
        0: 0,
        3: 5,
        6: 7.5,
        9: 10,
        12: 12.5
      }
      return percetXMonth;
  }

  setPrice = (installments, installmentsType) => {
    const discount = this.finalPrice * (1 - this.discountPercent/100)
    const { total } =  this.propsProduct && this.propsProduct.priceData
    if (installmentsType === 2) {
      if(installments) {
        this.setState({
          priceInstallments: this.finalPrice,
          monthSelected: installments
        })
      } else {
        this.setState({
          priceInstallments: discount,
          monthSelected: installments
        })
      }
    } else if (installmentsType === 1) {
      const percetXMonth = this.arrayPercentInstallments()
      const price = (percetXMonth[installments]/100 + 1) * total
      this.setState({
        priceInstallments: price,
      })
    }
    
  }

  hasDiscount = ( product ) => {
    const { installmentsType, discountReason } = product
    const discount = this.finalPrice * (1 - this.discountPercent/100)
    const hasDiscount = !this.props.isAdvance && installmentsType === 2
    let propsProduct = {
      priceData: {
        price: 0,
        discount: 0,
        total: hasDiscount ? discount : this.finalPrice,
        currencyData: product.currency
      },
      includes: product.includes,
      hasDiscount: false,
      productName: product.name,
      discountPercent: this.discountPercent,
      discountReason: discountReason,
      installmentsType: installmentsType
    }

    return propsProduct;
  }

  /**
   * @param {String} paymentMethod - the payment method chosen
   * @returns {void} .
   */
  handlePaymentMethodSelection = ( paymentMethod ) => {
    this.setState( { displayForm: true, paymentMethod: paymentMethod } )
  }

  /**
   * Function to change the error state
   * @param {boolean} error - boolean
   * @returns {void} .
   */
  onError = ( error ) => {
    this.setState( { error,coupon: null } )
  }

  /**
   * Function to handle the success of complete a payment
   * @param {Object} response - success response object
   * @return {void} .
   */
  handleSuccess = ( response ) => {
    let mercadoPagoOxxoUrl = null
    let conektaOxxoUrl = null
    if ( response.mercadoPagoOxxoUrl ) {
      mercadoPagoOxxoUrl = response.mercadoPagoOxxoUrl
    } else if ( response.reference && response.conektaPaymentId ) {
      conektaOxxoUrl = `/checkout/oxxo/${ response.conektaPaymentId }`
    }
    this.setState( {
      mercadoPagoOxxoUrl,
      conektaOxxoUrl,
      customerName: response.name,
      success: true
    } )
  }

  /**
   * Function to handler the error payment
   * @param {Object} response - response of the back when trying a payment
   * @returns {void} .
   */
  handleError = ( response ) => {
    this.setState( { error: true, responseError: response } )
  }

  /**
   * Component Render
   * @return {void} .
   */
  render() {
    const {
      error,
      success,
      customerName,
      responseError,
      mercadoPagoOxxoUrl,
      conektaOxxoUrl,
      data,
      paymentMethod,
      coupon,
      couponMobile,
      priceInstallments,
      monthSelected
    } = this.state
    const {
      showPersonalData,
      showCoupon,
      showAddress,
      cardPaymentMethod,
      cashPaymentMethod,
      productKeyName,
      paymentKeyName,
      country,
      isAdvance,
      customerId,
      customerData,
    } = this.props
    this.propsProduct.coupon = coupon
    this.propsProduct.couponMobile = couponMobile
    if ( error ) {
      return (
        <Error
          handleErrorButton={this.onError}
          responseError={responseError}
        />
      )
    }
    if ( success ) {
      return (
        <Success
          name={customerName}
          moonsEmail={this.moonsMail}
          mercadoPagoOxxoUrl={mercadoPagoOxxoUrl}
          conektaOxxoUrl={conektaOxxoUrl}
        />
      )
    }
    console.log( customerData )
    const checkoutFormComponent = (
    <><div className={style.TitleUser}>Hola <b>{this.patientName}</b>,<br/> estas a un paso de cambiar tu sonrisa</div>
      <div className={style.ContainerPayment}>
        <div className={style.TitleCheckout}>Método de Pago</div>
        <PaymentSelection
          handlePaymentMethodSelection={this.handlePaymentMethodSelection}
          cardPaymentMethod={cardPaymentMethod}
          cashPaymentMethod={cashPaymentMethod}
          cardButtonText="Tarjeta de Crédito"
          cashButtonText="Depósito en Oxxo"
        />
        <CheckoutForm
          country={country}
          customerId={customerId}
          productKeyName={productKeyName}
          paymentKeyName={paymentKeyName}
          handleSuccess={this.handleSuccess}
          handleError={this.handleError}
          data={data}
          showPersonalData={showPersonalData}
          showAddress={showAddress}
          showCoupon={showCoupon}
          paymentMethod={paymentMethod}
          isAdvance={isAdvance}
          customerData={customerData}
          validCoupon={this.validCoupon}
          setPrice={this.setPrice}
        />
      </div ></>
    )

    const logoComponent = (
      <div className={style.Logo}>
        <img
          alt="logo"
          src={logo}
        />
      </div>
    )

    return (
      <div className={style.ContainerFlex}>
        <div className={style.Mobile}>
          {logoComponent}
          <ProductInformation
            {...this.propsProduct}
            priceInstallments= {priceInstallments}
            monthSelected= {monthSelected}
          />
          {checkoutFormComponent}
        </div>
        <div className={style.Desktop}>
          <div className={style.Right}>
            {logoComponent}
            {checkoutFormComponent}
          </div>
          <div className={style.Square}></div>
          <div className={style.Left}>
            <ProductInfoDesktop
              {...this.propsProduct}
              priceInstallments= {priceInstallments}
              monthSelected= {monthSelected}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter( Checkout )
