
const messages = {
  titleInfo: 'Resumen de Cuenta',
  price: 'Precio',
  total: 'Total',
  discount: 'Descuento',
  kitMoons: 'Kit moons',
  include: 'Incluye'
}

export default messages
